import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, BrowserWindow, BrowserWindowSymbol } from '@wix/thunderbolt-symbols'
import { DashboardWixCodeSdkHandlers, ProxifiedDashboardApi } from '../types'
import { wrap, transfer, windowEndpoint, createEndpoint, Remote } from 'comlink/dist/esm/comlink.js' // eslint-disable-line no-restricted-syntax

const getDashboardApiFactory = (window: BrowserWindow) => {
	let dashboardApi: Remote<ProxifiedDashboardApi> | null = null

	return () => {
		if (!dashboardApi) {
			dashboardApi = wrap<ProxifiedDashboardApi>(windowEndpoint(window!.parent))
		}
		return dashboardApi
	}
}

export const dashboardWixCodeSdkHandlers = withDependencies(
	[BrowserWindowSymbol],
	(window: BrowserWindow): SdkHandlersProvider<DashboardWixCodeSdkHandlers> => {
		const getDashboardApi = getDashboardApiFactory(window)

		return {
			getSdkHandlers() {
				return {
					async getDashboardApi() {
						/// "Lazy" loading so the sled test has an opportunity to highjack window.parent...
						const dashboardApi = getDashboardApi()
						const port = await dashboardApi[createEndpoint]()

						return transfer(port, [port])
					},
				}
			},
		}
	}
)
